import {
  AgreementBuilderTemplateModel,
  DynamicFieldModel,
  MergeTagModel,
} from "@platform-app/app/agreement-builder/shared/models";
import { GetMatchingAffiliationAgreementDraftsMatchingDraftAgreement } from "@platform-app/app/core/api/models";
import { AffiliationAgreementInitialFieldsModel } from "@platform-app/app/main/shared/models";

export class UpdateAgreementField {
  static readonly type = "[Agreement Builder page] Update Agreement Field";
  constructor(public payload: { field: DynamicFieldModel; dynamic: boolean }) {}
}

export class GetCurrentAffiliationAgreement {
  static readonly type =
    "[Agreement Builder page] Get Current Affiliation Agreement";
  constructor(public payload: { agreementId: string }) {}
}

export class EditorContentUpdated {
  static readonly type = "[Agreement Builder page] Editor Content Updated";
  constructor(public payload: { content: string | null }) {}
}

export class UpdateMergeTagsList {
  static readonly type = "[Agreement Builder page] Update Merge Tags List";
  constructor(public payload: { tags: MergeTagModel[] }) {}
}

export class LoadTemplateOptions {
  static readonly type = "[Agreement Builder page] Load Template Options";
}

export class AgreementTemplateSelected {
  static readonly type = "[Agreement Builder page] Agreement Template Selected";
  constructor(
    public payload: { template: AgreementBuilderTemplateModel | null },
  ) {}
}

export class AddAgreementTemplate {
  static readonly type = "[Agreement Builder page] Add Agreement Template";
  constructor(public payload: { templateName: string | null }) {}
}

export class UpdateSelectedAgreementTemplateContent {
  static readonly type =
    "[Agreement Builder page] Update Agreement Template Content";
  constructor(public payload: { templateName: string }) {}
}

export class RenameAgreementTemplate {
  static readonly type = "[Agreement Builder page] Rename Agreement Template";
  constructor(public payload: { templateId: string; templateName: string }) {}
}

export class DeleteAgreementTemplate {
  static readonly type = "[Agreement Builder page] Delete Agreement Template";
  constructor(public payload: { templateId: string }) {}
}

export class SendPartnerInvitation {
  static readonly type = "[Agreement Builder page] Send Partner Invitation";
  constructor(public payload: { invitationMessage: string | null }) {}
}

export class LoadCurrentAgreementBuilderCommentThreads {
  static readonly type =
    "[Agreement Builder page] Load Agreement Builder Comment Threads";
}

export class UpdateEditorCommentThreadsIds {
  static readonly type =
    "[Agreement Builder page] Update Editor Comment Threads Ids";
  constructor(public payload: { ids: string[] }) {}
}

export class SetDraftCommentThreadId {
  static readonly type = "[Agreement Builder page] Set Draft Comment Id";
  constructor(public payload: { commentId: string | null }) {}
}

export class SyncCurrentAgreementBuilderContentToBackend {
  static readonly type =
    "[Agreement Builder page] Sync Builder Content To Backend";
}

export class RequestChangesForCurrentAgreement {
  static readonly type =
    "[Agreement Builder page] Request Changes For Agreement";
  constructor(public payload: { message: string | null }) {}
}

export class ProvideChangesForCurrentAgreement {
  static readonly type =
    "[Agreement Builder page] Provide Changes For Agreement";
  constructor(
    public payload: {
      comment: string | null;
      submitChanges: boolean;
    },
  ) {}
}

export class AcceptCurrentAgreement {
  static readonly type = "[Agreement Builder page] Accept Current Agreement";
}

export class ResetAgreementBuilderState {
  static readonly type =
    "[Agreement Builder page] Reset Agreement Builder State";
}

export class SetConflictingDraftAgreements {
  static readonly type =
    "[Agreement Builder page] Set Conflicting Draft Agreements";
  constructor(
    public payload: {
      agreements: GetMatchingAffiliationAgreementDraftsMatchingDraftAgreement[];
    },
  ) {}
}

export class SaveAgreementAsDraft {
  static readonly type = "[Agreement Builder page] Save Agreement as Draft";
}

export class CancelCurrentAgreement {
  static readonly type = "[Agreement Builder page] Cancel Current Agreement";
}

export class SetInitialAgreementFields {
  static readonly type =
    "[Agreement Builder page] Set Initial Agreement Fields";
  constructor(
    public payload: {
      fields: AffiliationAgreementInitialFieldsModel;
    },
  ) {}
}

export class SetImportedDocumentName {
  static readonly type = "[Agreement Builder page] Set Imported Document Name";
  constructor(
    public payload: {
      documentName: string;
    },
  ) {}
}
